<template>
  <div class="error-logo">
    <img
      :src="logo"
      alt="Financial and Business News"
      title="Financial and Business News"
      class="error-logo__image"
    />
    <div class="error-logo__text__wrapper">
      <p class="error-logo__text subtitle-1">WE'RE SORRY</p>
      <p class="error-logo__text subtitle-1">
        THIS PAGE HAD A HARD LANDING
      </p>
    </div>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FIXED_LAYOUT } from 'enums/fixed-layout'

export default {
  name: 'AErrorLogo',
  props: {
    fixedLayout: propValidator(
      [PROP_TYPES.STRING],
      false,
      FIXED_LAYOUT.DESKTOP,
      value => value == null || Object.values(FIXED_LAYOUT).includes(value)
    )
  },
  computed: {
    logo() {
      return this.fixedLayout === FIXED_LAYOUT.MOBILE
        ? this.$helper.getLinkToFmBucketFile('footer-logo-sm.svg')
        : this.$helper.getLinkToFmBucketFile('footer-logo.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.error-logo {
  display: flex;
  flex-direction: column;

  @include mobile {
    flex-direction: row;
    align-items: center;
  }

  &__image {
    display: block;
    width: 150px;
    margin: 0 auto 30px auto;

    @include mobile {
      width: 30px;
      height: 46px;
      margin: 0 25px 0 0;
    }
  }

  .error-logo__text__wrapper {
    @include mobile {
      width: percentage(180 / 290);
    }
  }

  &__text {
    text-align: center;

    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
